<template>
  <section class="pie-c3">
    <vue-c3 :handler="piecharthandler"></vue-c3>
  </section>
</template>

<script>
  import Vue from 'vue'
  import VueC3 from 'vue-c3'

  export default {
    name: 'pie-c3',
    components: {
      VueC3
    },

    data () {
      return {
        piecharthandler: new Vue(),
      }
    },

    mounted () {
      const options = {
        data: {
          columns: [
            ['กำลังจัดทำ', 30],
            ['รอตรวจสอบ', 120],
            ['ยืนยันราคาแล้ว', 120],
            ['Revise ราคา', 120],
            ['ยกเลิก', 120],
            ['ตรวจสอบไม่ผ่าน', 120],
            ['ได้งาน', 120],
            ['เทียบราคา', 120],
            ['คู่เทียบ', 120],
            ['ไม่ได้งาน', 120],
          ],
          type: 'pie'
        },
        color: {
          pattern: ['#d9d9d9', '#ffff00', '#00b0f0',
            '#ff9900', '#ff0000', '#931d8b',
            '#00b050', '#808080', '#833c0c', '#ff0000'
          ]
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        }
      }

      this.piecharthandler.$emit('init', options)
    }
  }
</script>